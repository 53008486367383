import Vue from "vue";
import Vuelidate from "vuelidate";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";
import { email, max, min, numeric, required } from "vee-validate/dist/rules";

extend("email", email);
extend("max", max);
extend("min", min);
extend("numeric", numeric);
extend("required", required);
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} je zahtevano polje"
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Ponovljeno geslo se ne ujema."
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.use(Vuelidate);
