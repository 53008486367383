import allergens from "../data/allergens";

let state = {
  barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
  barImage: "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-3.jpg",
  drawer: null,
  categories: [{
    id: 1,
    name: "Burgerji",
    items: [{
      id: 1,
      name: "Cheese burger",
      price: "10",
      description: "Lorem ipsum"
    }]
  }],
  authUser: null,
  lang: "en",
  languages: [{
    text: "English",
    value: "en"
  },
  {
    text: "Slovenien",
    value: "sl"
  },
  {
    text: "German",
    value: "ge"
  }
  ],
  allergens: allergens
};

export default state;
