// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import "./plugins/biomp.js";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import Notifications from "vue-notification";

Vue.component(
  "nprogress-container",
  require("vue-nprogress/src/NprogressContainer").default
);

import NProgress from "vue-nprogress";
import './registerServiceWorker'

/*
or for SSR:
import Notifications from 'vue-notification/dist/ssr.js'
*/

Vue.use(Notifications);
Vue.use(NProgress);

Vue.config.productionTip = false;

const nprogress = new NProgress({ parent: ".v-application--wrap" });

new Vue({
  nprogress,
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
