import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "vuetify/lib/locale/en";

Vue.use(VueI18n);

const messages = {
  en: {
    ...require("@/locales/en.json"),
    $vuetify: en
  },
  sl: {
    ...require("@/locales/sl.json")
  }
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "sl",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "sl",
  messages,
  silentTranslationWarn: true
});
