import { db } from "../firebase";
import * as firebase from "firebase";

let actions = {
  SAVE_CATEGORY({ state, commit }, { category }) {
    db.ref("meni/" + state.authUser.id + "/categories/").push(category);
  },
  UPDATE_CATEGORY({ state, commit }, { category }) {
    db.ref("meni/" + state.authUser.id + "/categories/" + category.id).set(
      category
    );
  },
  DELETE_CATEGORY({ state, commit }, { categoryId }) {
    state.categories.forEach((val) => {
      if (val.parentId === categoryId)
        db.ref("meni/" + state.authUser.id + "/categories/" + val.id).remove(); // remove all subcategories of a parent
    })

    return db
      .ref("meni/" + state.authUser.id + "/categories/" + categoryId)
      .remove();
  },
  FETCH_CATEGORIES({ state, commit }) {
    db.ref("meni/" + state.authUser.id + "/categories/").on("value", function (
      snapshot
    ) {
      var categories = [];
      var categoriesDir = snapshot.val();
      if (categoriesDir) {
        Object.keys(categoriesDir).forEach(function (key) {
          categoriesDir[key]["id"] = key;
          categories.push(categoriesDir[key]);
        });
      }
      commit("SET_CATEGORIES", categories);
    });
  },
  FIND_MENI({ state, commit }, { link }) {
    return new Promise(async function (resolve, reject) {
      await db
        .ref("users")
        .once("value")
        .then(async function (snapshot) {
          var users = snapshot.val();
          if (users) {
            let foundKey = null;
            Object.keys(users).forEach(async function (key) {
              if (users[key].link == link) {
                foundKey = key;
              }
            });
            if (foundKey) {
              await new Promise(function (resolve2, reject2) {
                db.ref("meni/" + foundKey + "/categories/")
                  .once("value")
                  .then(function (snapshot) {
                    var categories = [];
                    var categoriesDir = snapshot.val();
                    if (categoriesDir) {
                      Object.keys(categoriesDir).forEach(function (key) {
                        categoriesDir[key]["id"] = key;
                        categories.push(categoriesDir[key]);
                      });
                    }
                    commit("SET_CATEGORIES", categories);
                    commit("SET_COLOR", users[foundKey].color);
                    commit("SET_RESOURCE_ID", foundKey);
                    commit("SET_COMPANY_NAME", users[foundKey].company);
                    return resolve2();
                  })
                  .catch(err => {
                    return reject2();
                  });
              });
            } else commit("SET_CATEGORIES", []);
            return resolve();
          }
        });
    });
  },
  SAVE_ENTRY_IMAGE({ state, commit }, { file, userId, itemId }) {
    var storageRef = firebase.storage().ref();
    var meniRef = storageRef.child("meni/" + userId + "/" + itemId);
    //Update logo
    if (file) {
      meniRef.put(file).then(function (snapshot) {
        console.log("Uploaded a blob or file!");
      });
    } else {
      // Delete the file
      meniRef
        .delete()
        .then(function () {
        })
        .catch(function (error) {
        });
    }
  },
  SAVE_ENTRY({ state, commit, dispatch }, { categoryId, item }) {
    db.ref(
      "meni/" + state.authUser.id + "/categories/" + categoryId + "/entries/"
    ).push(item).then((snap) => {
      const key = snap.key;
      dispatch("SAVE_ENTRY_IMAGE", {
        file: item.image,
        userId: state.authUser.id,
        itemId: key
      });

      item.id = key;
      dispatch("UPDATE_ENTRY", { // hacky solution, not the best
        file: item.image,
        categoryId: categoryId,
        subcategoryId: categoryId,
        entry: item,
      });
    });
  },
  UPDATE_ENTRY({ state, commit, dispatch }, { file, categoryId, entry, subcategoryId }) {
    if (!entry.image)
      entry.image = null;

    if (subcategoryId == null) {
      state.categories.forEach(cat => {
        if (cat.id == categoryId && cat.parentId)
          subcategoryId = cat.parentId;
      });
    }

    if (categoryId != subcategoryId && subcategoryId != null) {
      dispatch("DELETE_ENTRY", { entryId: entry.id, categoryId: categoryId });
      categoryId = subcategoryId;
    }
    db.ref(
      "meni/" +
      state.authUser.id +
      "/categories/" +
      categoryId +
      "/entries/" +
      entry.id
    ).set(entry).then((snap) => {
      dispatch("SAVE_ENTRY_IMAGE", {
        file: file,
        userId: state.authUser.id,
        itemId: entry.id
      });
    });
  },
  DELETE_ENTRY({ state, commit }, { categoryId, entryId }) {
    return db
      .ref(
        "meni/" +
        state.authUser.id +
        "/categories/" +
        categoryId +
        "/entries/" +
        entryId
      )
      .remove();
  },
  SAVE_USER({ commit }, { user }) {
    db.ref("users/" + user.id).set(user);
  },
  GET_USER({ commit }, { id }) {
    return db.ref("users/" + id).once("value");
  },
  GET_MENI({ state }, { id }) {
    return db.ref("meni/" + id).once("value");
  },
  SAVE_MENI({ state }, { id, meni }) {
    db.ref("meni/" + id).set(meni);
  },
  GET_USERS() {
    return db.ref("users").once("value");
  },
  SET_CATEGORIES({ commit }, payload) {
    commit("SET_CATEGORIES", payload);
  }
};

export default actions;
