let mutations = {
  SET_LANG(state, lang) {
    state.lang = lang;
  },
  SET_USER(state, user) {
    state.authUser = user;
  },
  SET_BAR_IMAGE(state, payload) {
    state.barImage = payload;
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload;
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_COLOR(state, color) {
    state.meniColor = color;
  },
  SET_RESOURCE_ID(state, id) {
    state.resource_id = id;
  },
  SET_COMPANY_NAME(state, name) {
    state.company_name = name;
  }
};

export default mutations;
