import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/i18n";
import "@/sass/overrides.sass";

Vue.use(Vuetify);

const theme = {
  primary: "#2EC4B6",
  secondary: "#CBF3F0",
  accent: "#9C27b0",
  info: "#FFBF69"
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      dark: theme,
      light: theme
    }
  }
});
