import * as firebase from "firebase";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCUl-249Uz8q3Rp-A8cEfd8NTSYJrPNJdQ",
  authDomain: "digimeni-mvp.firebaseapp.com",
  databaseURL: "https://digimeni-mvp.firebaseio.com",
  projectId: "digimeni-mvp",
  storageBucket: "digimeni-mvp.appspot.com",
  messagingSenderId: "832538645543",
  appId: "1:832538645543:web:b4a53b5a05dabfede56a02",
  measurementId: "G-44L4WH2PQ4",
};

/*const firebaseConfig = {
  apiKey: "AIzaSyDPUbhntgK3lf2lxcU5_pV_DNojfti26nQ",
  authDomain: "digimeni-test.firebaseapp.com",
  databaseURL: "https://digimeni-test.firebaseio.com",
  projectId: "digimeni-test",
  storageBucket: "digimeni-test.appspot.com",
  messagingSenderId: "1060321856284",
  appId: "1:1060321856284:web:a8cc2791a5af277f5a48d9",
  measurementId: "G-SYN3DC88Q5"
};*/
// Initialize Firebase
let firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = firebaseApp.database();
