export default {
  'sl': [{
      id: 1,
      label: '1 / G',
      title: 'ŽITA, KI VSEBUJEJO GLUTEN',
      description: '( pšenica, rž, ječmen, oves, pira, kamut ali njihove križane vrste ali proizvodi iz njih)',
    },
    {
      id: 2,
      label: '2 / R1',
      title: 'RAKI IN PROIZVODI IZ NJIH',
      description: '',
    },
    {
      id: 3,
      label: '3 / J',
      title: 'JAJCA IN PROIZVODI IZ NJIH',
      description: '',
    },
    {
      id: 4,
      label: '4 / R2',
      title: 'RIBE IN PROZVODI IZ NJIH',
      description: ''
    },
    {
      id: 5,
      label: '5 / A',
      title: 'ARAŠIDI IN PROIZVODI Z NJIH',
      description: ''
    },
    {
      id: 6,
      label: '6 / S',
      title: 'ZRNJE SOJE IN PROIZVODI IZ NJIH',
      description: ''
    },
    {
      id: 7,
      label: '7 / L',
      title: 'MLEKO IN MLEČNI PROIZVODI, KI VSEBUJEJO LAKTOZO',
      description: ''
    },
    {
      id: 8,
      label: '8 / O',
      title: 'OREŠKI IN PROIZVODI IZ NJIH',
      description: '(mandeljni, orehi, lešniki, indijski oreščki, ameriški orehi, brazilski oreščki, pistacija, makadamija)'
    },
    {
      id: 9,
      label: '9 / Z',
      title: 'LISTNA ZELENA IN PROIZVODI IZ NJIH',
      description: ''
    },
    {
      id: 10,
      label: '10 / GS',
      title: 'GORČIČNO SEME IN PROIZVODI IZ NJIH',
      description: ''
    },
    {
      id: 11,
      label: '11 / SS',
      title: 'SEZAMOVO SEME IN PROIZVODI IZ NJIH',
      description: ''
    },
    {
      id: 12,
      label: '12 / Ž',
      title: 'ŽVEPLOV DIOKSID IN SULFITI',
      description: '(v koncentraciji več kot 100mg/kg ali 10mg/l, izraženi kot SO2)'
    },
    {
      id: 13,
      label: '13 / V',
      title: 'VOLČJI BOB IN PROIZVODI IZ NJIH',
      description: ''
    },
    {
      id: 14,
      label: '14 / M',
      title: 'MEHKUŽCI IN PROIZVODI IZ NJIH',
      description: ''
    },
  ],
  'en': [{
      id: 1,
      label: '1 / G',
      title: 'ŽITA, KI VSEBUJEJO GLUTEN',
      description: '( pšenica, rž, ječmen, oves, pira, kamut ali njihove križane vrste ali proizvodi iz njih)',
    },
    {
      id: 2,
      label: '2 / R1',
      title: 'RAKI IN PROIZVODI IZ NJIH',
      description: '',
    },
    {
      id: 3,
      label: '3 / J',
      title: 'JAJCA IN PROIZVODI IZ NJIH',
      description: '',
    },
    {
      id: 4,
      label: '4 / R2',
      title: 'RIBE IN PROZVODI IZ NJIH',
      description: ''
    },
    {
      id: 5,
      label: '5 / A',
      title: 'ARAŠIDI IN PROIZVODI Z NJIH',
      description: ''
    },
    {
      id: 6,
      label: '6 / S',
      title: 'ZRNJE SOJE IN PROIZVODI IZ NJIH',
      description: ''
    },
    {
      id: 7,
      label: '7 / L',
      title: 'MLEKO IN MLEČNI PROIZVODI, KI VSEBUJEJO LAKTOZO',
      description: ''
    },
    {
      id: 8,
      label: '8 / O',
      title: 'OREŠKI IN PROIZVODI IZ NJIH',
      description: '(mandeljni, orehi, lešniki, indijski oreščki, ameriški orehi, brazilski oreščki, pistacija, makadamija)'
    },
    {
      id: 9,
      label: '9 / Z',
      title: 'LISTNA ZELENA IN PROIZVODI IZ NJIH',
      description: ''
    },
    {
      id: 10,
      label: '10 / GS',
      title: 'GORČIČNO SEME IN PROIZVODI IZ NJIH',
      description: ''
    },
    {
      id: 11,
      label: '11 / SS',
      title: 'SEZAMOVO SEME IN PROIZVODI IZ NJIH',
      description: ''
    },
    {
      id: 12,
      label: '12 / Ž',
      title: 'ŽVEPLOV DIOKSID IN SULFITI',
      description: '(v koncentraciji več kot 100mg/kg ali 10mg/l, izraženi kot SO2)'
    },
    {
      id: 13,
      label: '13 / V',
      title: 'VOLČJI BOB IN PROIZVODI IZ NJIH',
      description: ''
    },
    {
      id: 14,
      label: '14 / M',
      title: 'MEHKUŽCI IN PROIZVODI IZ NJIH',
      description: ''
    },
  ]
}
