import Vue from "vue";
import Router from "vue-router";
import store from "./store/index";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [{
      name: "meni",
      path: "/meni/:link",
      component: () => import("@/views/meni/Meni"),
      meta: {
        requiresAuth: false
      }
    },
    {
      name: "alergeni",
      path: "/alergeni/:link",
      component: () => import("@/views/meni/Alergeni"),
      meta: {
        requiresAuth: false
      }
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/views/auth/Login"),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/register",
      component: () => import("@/views/auth/Register"),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/logout",
      component: () => import("@/views/auth/Logout"),
      meta: {
        requiresAuth: true
      }
    },
    {
      name: "reset-password",
      path: "/reset-password",
      component: () => import("@/views/auth/ResetPassword"),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/",
      component: () => import("@/views/dashboard/Index"),
      meta: {
        requiresAuth: true
      },
      children: [
        // Preview
        {
          name: "Dashboard",
          path: "",
          component: () => import("@/views/dashboard/pages/Preview")
        },
        // Pages
        {
          name: "Profile",
          path: "pages/user",
          component: () => import("@/views/dashboard/pages/UserProfile")
        },
        {
          name: "Qrcode",
          path: "pages/qrcode",
          component: () => import("@/views/dashboard/pages/Qrcode")
        },
        {
          name: "Admin",
          path: "pages/admin",
          component: () => import("@/views/dashboard/pages/Admin")
        },
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.authUser && store.state.authUser.verified) {
      if (to.name == "Admin" && !store.state.authUser.isAdmin) {
        next("/");
      }
      next();
      return;
    }
    if (store.state.authUser && !store.state.authUser.verified) {
      store.commit("SET_USER", null);
    }
    next("/login");
  } else {
    if (!store.state.authUser || to.name == "meni" || to.name == "alergeni") {
      next();
      return;
    }
    next("/");
  }
});

export default router;
